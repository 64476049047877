import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const DevTimeline = () => {
  return (
    <Container>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h4" component="span">
              Pointie Update:
              <br />
              GCR Integration
            </Typography>
            <Typography variant="body2">v0.4.0 on June 13th, 2024</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h4" component="span">
              Pointie Update:
              <br />
              UX Improvements
            </Typography>
            <Typography variant="body2">v0.3.1 on Nov 26th, 2023</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h4" component="span">
              Pointie Updates:
              <br />
              EPP, deals
            </Typography>
            <Typography variant="body2">v0.1.0 on Nov 14th, 2023</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h4" component="span">
              Web site Release
            </Typography>
            <Typography variant="body2">Aug 21st</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h4" component="span">
              Chrome Store Release
            </Typography>
            <Typography variant="body2">v0.0.1 on July 18th, 2023</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Container>
  );
};

export default DevTimeline;
